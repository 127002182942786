import React, { useCallback, useEffect, useState } from "react";
import { Button, Alert } from "@material-tailwind/react";

import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

import { GetTreeCategory } from "@/services/CategoryService";
import { addTechnology } from "@/services/TechnologyService";
import { uploadImg } from "@/services/UploadService";

// 布局
import CommonLayout from "@/layouts/CommonLayout";

// 技术收录表单
export default function TechRecordingForm() {
  const [categories, setCategories] = useState<
    API.Category.TreeCategoryDataType[]
  >([]);
  const [isShowAlert, setIsShowAlert] = useState(false);
  // 获取分类
  useEffect(() => {
    GetTreeCategory().then((res) => {
      console.log("categories", res.data);
      setCategories(res.data);
    });
  }, []);

  // 图片上传
  // const [imgLogo, setImgLogo] = useState([]);
  const [imgLogo, setImgLogo] = useState("");
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      // console.log("上传图片组件：", acceptedFiles);
      const file = acceptedFiles.at(0);
      // console.log("file", file as Blob, file?.name);
      const formData = new FormData();
      formData.append("file", file as Blob, file?.name);
      // 上传图片
      uploadImg(formData).then((res) => {
        if (res.success) {
          setImgLogo(res.fileUrl);
        }
      });
    },
  });
  const logoThumb = imgLogo ? (
    <div className="inline-flex rounded-sm box-border h-32 w-32 p-4 border border-gray-400 ">
      <div className="flex">
        <img
          alt=""
          className="block w-full h-auto"
          src={imgLogo}
          // Revoke data uri after image is loaded
        />
      </div>
    </div>
  ) : (
    ""
  );
  // 技术详细介绍
  const [desc, setDesc] = useState("");
  const onChangeDesc = useCallback((value: string) => {
    setDesc(value);
  }, []);

  // 表单
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (d: any) => {
    // 提交表单
    // console.log("提交表单", d);
    // alert(JSON.stringify(d));
    // console.log("上传logo", imgLogo);
    let gitHubApi = "";
    if (d.gitHubUrl) {
      gitHubApi =
        "https://api.github.com/repos/" +
        d.gitHubUrl.replace("https://github.com/", "");
    }
    addTechnology({
      categoryId: d.categoryId,
      title: d.title,
      subTitle: d.subTitle,
      url: d.siteUrl,
      logo: d.logo,
      desc: desc,
      bgColor: d.bgColor,
      bgImage: d.bgImage,
      docUrl: d.docUrl,
      gitHubUrl: d.gitHubUrl,
      gitHubAPI: gitHubApi,
    }).then((res) => {
      if (res.success) {
        console.log("表单提交成功！");
        setIsShowAlert(true);
      }
    });
  };

  // 分类下拉选项
  const CategoryOptions = (data: API.Category.TreeCategoryDataType[]) => {
    let result: API.OptionDataType[] = [];
    if (data == null) {
      return "";
    }
    data.map((item) => {
      result.push({ value: item.id, label: item.title, level: item.level });
      if (item.childs) {
        TreeData(item.childs, result);
      }
    });
    return result.map((item, index) => {
      return (
        <option value={item.value} key={index}>
          {item.level == 2
            ? "--" + item.label
            : item.level == 3
            ? "----" + item.label
            : item.label}
        </option>
      );
    });
  };
  const TreeData = (
    data: API.Category.TreeCategoryDataType[],
    result: API.OptionDataType[]
  ) => {
    data.map((item) => {
      result.push({ value: item.id, label: item.title, level: item.level });
      if (item.childs) {
        TreeData(item.childs, result);
      }
    });
    return result;
  };

  return (
    <CommonLayout>
      <div className="flex justify-center min-h-screen px-8 py-1 bg-white">
        <div className="my-24">
          <h1 className="text-4xl font-extrabold text-[#512bd4]">技术收录</h1>
          <div className="top-1/2 px-8 py-1 bg-white">
            <Alert children="提交成功！" show={isShowAlert} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-1 mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                技术名称
              </label>
              <input
                {...register("title", { required: true })}
                type="text"
                placeholder="输入技术名称"
                className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.title?.type === "required" && (
                <p role="alert" className=" text-red-500 text-sm">
                  必填项
                </p>
              )}
            </div>
            <div className="flex-1 mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                技术简介
              </label>
              <input
                {...register("subTitle")}
                type="text"
                placeholder="输入技术名称"
                className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div className="flex-1 mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                官网地址
              </label>
              <input
                {...register("siteUrl", { required: true })}
                type="text"
                placeholder="官方网站地址"
                className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.siteUrl?.type === "required" && (
                <p role="alert" className=" text-red-500 text-sm">
                  必填项
                </p>
              )}
            </div>
            <div className="w-full mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                类别
              </label>
              <select
                {...register("categoryId", { required: true })}
                className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md"
              >
                {CategoryOptions(categories)}
              </select>
            </div>
            <div className="w-full mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                详细介绍
              </label>

              <SimpleMDE value={desc} onChange={onChangeDesc} />
            </div>

            <div className="w-full mt-4 flex justify-between">
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                  背景色
                </label>
                <input
                  {...register("bgColor")}
                  type="text"
                  placeholder="背景色"
                  className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className="w-4"></div>
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                  文档
                </label>
                <input
                  {...register("docUrl")}
                  type="text"
                  placeholder="文档地址"
                  className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                GitHub
              </label>
              <input
                {...register("gitHubUrl")}
                type="text"
                placeholder="GitHub"
                className="block w-full px-5 py-1 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div className="w-full mt-4 flex ">
              <div>
                <label
                  className="block mb-2 text-sm font-medium text-gray-600"
                  htmlFor="logo"
                >
                  Logo
                </label>
                <div
                  aria-label="upload file button"
                  role="button"
                  tabIndex={0}
                  // htmlFor="logo"
                  {...getRootProps({
                    className:
                      "flex flex-col items-center w-full max-w-lg p-2 mx-auto mt-2 text-center bg-white border-2 border-dashed cursor-pointer rounded-xl dropzone",
                  })}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                    ></path>
                  </svg>
                  <p className="mt-2 text-sm tracking-wide text-gray-500">
                    Upload your file SVG, PNG, JPG or GIF.
                  </p>
                  <input
                    id="logo"
                    type="file"
                    // {...register("logo", { required: true })}
                    {...register("logo")}
                    {...getInputProps()}
                    className="hidden"
                  />
                </div>
                {/* {errors.logo?.type === "required" && (
                    <p role="alert" className=" text-red-500 text-sm">
                      必填项
                    </p>
                  )} */}
                <aside className="flex flex-wrap mt-1">{logoThumb}</aside>
              </div>
              <div className="px-4">
                <label
                  className="block mb-2 text-sm font-medium text-gray-600"
                  htmlFor="cover"
                >
                  封面
                </label>
                <div
                  aria-label="upload file button"
                  role="button"
                  tabIndex={0}
                  // htmlFor="cover"
                  className="flex flex-col items-center w-full max-w-lg p-2 mx-auto mt-2 text-center bg-white border-2 border-dashed cursor-pointer rounded-xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                    ></path>
                  </svg>
                  <p className="mt-2 text-sm tracking-wide text-gray-500">
                    Upload your file SVG, PNG, JPG or GIF.
                  </p>
                  <input
                    id="cover"
                    type="file"
                    {...register("cover")}
                    className="hidden"
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-4 flex justify-center">
              <Button
                variant="gradient"
                color="blue"
                type="submit"
                className="w-full"
              >
                <span>提交</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </CommonLayout>
  );
}
