// 静态数据
import React, { useState, useEffect } from "react";

// 自定义的 Hook https://zh-hans.reactjs.org/docs/hooks-custom.html
function useProductData(props: any) {
  // 静态数据
  const staticProductInfos: API.TechInfoDataType[] = [
    {
      id: "67ff3b04-6326-11ed-a1be-fa6dfb0933c5",
      title: "React",
      subTitle: "用于构建用户界面的 JavaScript 库",
      url: "https://reactjs.org/", // 官网地址
      logo: "static/img/product/react.png",
      cover: "",
      bgColor: "bg-sky-500",
      shortDesc: "", // 简短描述
      tags: "React,", // 标签
      features: [
        {
          title: "声明式",
          desc: "React 使创建交互式 UI 变得轻而易举。为你应用的每一个状态设计简洁的视图，当数据变动时 React 能高效更新并渲染合适的组件。",
        },
        {
          title: "组件化",
          desc: "构建管理自身状态的封装组件，然后对其组合以构成复杂的 UI。",
        },
        {
          title: "一次学习，跨平台编写",
          desc: "无论你现在使用什么技术栈，在无需重写现有代码的前提下，通过引入 React 来开发新功能。",
        },
      ], // 特色
      desc: "",
      descFile: "/static/content/details/react.md",
      doc: "", // 使用文档
      tutorial: "", //教程
      example: "", // 示例/范例
      categoryId: "23227021-67dc-11ed-b67e-caa76bf5716c", // 类目
      categoryTitle: "前端框架",
      gitHubUrl: "https://github.com/facebook/react", // github 地址
      gitHubAPI: "https://api.github.com/repos/facebook/react", // github 点赞数量
    },
    {
      id: "bea21dd3-632a-11ed-9a83-fa6dfb0933c5",
      title: "Vue",
      subTitle: "渐进式 JavaScript 框架",
      url: "https://vuejs.org/",
      logo: "static/img/product/vue.jpg",
      cover: "",
      bgColor: "bg-emerald-500",
      shortDesc: "", // 简短描述
      tags: "", // 标签
      features: [
        {
          title: "易学易用",
          desc: "基于标准 HTML、CSS 和 JavaScript 构建，提供容易上手的 API 和一流的文档。",
        },
        {
          title: "性能出色",
          desc: "经过编译器优化、完全响应式的渲染系统，几乎不需要手动优化。",
        },
        {
          title: "灵活多变",
          desc: "丰富的、可渐进式集成的生态系统，可以根据应用规模在库和框架间切换自如。",
        },
      ], // 特色
      desc: "",
      descFile: "/static/content/details/vue.md",
      doc: "", // 使用文档
      tutorial: "", //教程
      example: "", // 示例/范例
      categoryId: "23227021-67dc-11ed-b67e-caa76bf5716c", // 类目
      categoryTitle: "前端框架",
      gitHubUrl: "https://github.com/vuejs/vue", // github 地址
      gitHubAPI: "https://api.github.com/repos/vuejs/vue", // github 点赞数量
    },
    {
      id: "c6391b9a-632a-11ed-8208-fa6dfb0933c5",
      title: "Angular",
      subTitle: "渐进式 JavaScript 框架",
      url: "https://angular.io/",
      logo: "static/img/product/angular.jpg",
      cover: "",
      bgColor: "bg-red-600",
      shortDesc: "", // 简短描述
      tags: "", // 标签
      features: [
        {
          title: "横跨多种平台",
          desc: "学会用 Angular 构建应用，然后把这些代码和能力复用在多种多种不同平台的应用上 —— Web、移动 Web、移动应用、原生应用和桌面原生应用。",
        },
        {
          title: "速度与性能",
          desc: "通过 Web Worker 和服务端渲染，达到在如今(以及未来）的 Web 平台上所能达到的最高速度。",
        },
        {
          title: "美妙的工具",
          desc: "使用简单的声明式模板，快速实现各种特性。使用自定义组件和大量现有组件，扩展模板语言。在几乎所有的 IDE 中获得针对 Angular 的即时帮助和反馈。所有这一切，都是为了帮助你编写漂亮的应用，而不是绞尽脑汁的让代码“能用”。",
        },
      ], // 特色
      desc: "",
      descFile: "/static/content/details/angular.md",
      doc: "", // 使用文档
      tutorial: "", //教程
      example: "", // 示例/范例
      categoryId: "23227021-67dc-11ed-b67e-caa76bf5716c", // 类目
      categoryTitle: "前端框架",
      gitHubUrl: "https://github.com/angular/angular", // github 地址
      gitHubAPI: "https://api.github.com/repos/angular/angular", // github 点赞数量
    },
    {
      id: "1e2a641b-674a-11ed-8f21-caa76bf5716c",
      title: "Ember",
      subTitle: "为雄心勃勃的web开发人员提供的框架",
      url: "https://emberjs.com/",
      logo: "static/img/product/ember-logo.svg",
      cover: "",
      bgColor: "bg-orange-500",
      shortDesc: "", // 简短描述
      tags: "", // 标签
      features: [], // 特色
      desc: "",
      descFile: "/static/content/details/ember.md",
      doc: "", // 使用文档
      tutorial: "", //教程
      example: "", // 示例/范例
      categoryId: "23227021-67dc-11ed-b67e-caa76bf5716c", // 类目
      categoryTitle: "前端框架",
      gitHubUrl: "https://github.com/emberjs/ember.js", // github 地址
      gitHubAPI: "https://api.github.com/repos/emberjs/ember.js", // github 点赞数量
    },
  ];
  const staticProductDetails = [
    {
      id: "",
      desc: "", // markdown
      version: "", // 最新版本
      updated: "", // 最近更新
      viewCount: "", // 本站浏览次数
      commentCount: "", // 本站评论数量
    },
    // {
    //   id: "",
    //   desc: "", // markdown
    //   version: "", // 最新版本
    //   updated: "", // 最近更新
    //   viewCount: "", // 本站浏览次数
    //   commentCount: "", // 本站评论数量
    // },
    // {
    //   id: "",
    //   desc: "", // markdown
    //   version: "", // 最新版本
    //   updated: "", // 最近更新
    //   viewCount: "", // 本站浏览次数
    //   commentCount: "", // 本站评论数量
    // },
  ];

  // 产品基本信息
  const [productInfos, setProductInfos] = useState(staticProductInfos);
  // 产品描述信息
  const [productDescs, setProductDescs] = useState([]);

  return { productInfos, productDescs };
}

export default useProductData;
