import React from "react";

import { Typography } from "@material-tailwind/react";

import "@/assets/css/product.css";

import ProductMainInfoSection from "@/components/sections/productMainInfoSection";
import ProductDescSection from "@/components/sections/productDescSection";
import Footer from "@/components/layout/footer";

// 通用技术主题首页
export default function CommonTheme() {
  return (
    <div className="product-page">
      <main>
        <ProductMainInfoSection />
        <ProductDescSection />
      </main>
      <Footer />
    </div>
  );
}
