import React from "react";
import { useState, useEffect } from "react";
import { Fragment } from "react";

// react-copy-to-clipboard components
// import { CopyToClipboard } from "react-copy-to-clipboard";

// @material-tailwind/react components
import { Typography, Input, Button, Tooltip } from "@material-tailwind/react";

// import "../../assets/css/home.css";

export default function Header() {
  const [version, setVersion] = useState("npm i @material-tailwind/react");
  // const [copied, setCopied] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 3000);
  // }, [copied]);

  return (
    <div className="section-header bg-cover bg-center bg-no-repeat gradient-light-blue">
      <div className="relative z-50 h-fit py-20 lg:py-32">
        <div className="container mx-auto px-4">
          <div className="text-center">
            {/* flex flex-wrap items-center justify-center */}
            <Fragment>
              <Typography variant="h1" color="white">
                技术池
              </Typography>
              <Typography variant="lead" color="white" className=" mt-8">
                汇聚互联网软件研发行业技术，服务于从事互联网行业人才.
              </Typography>
              <Typography variant="lead" color="white" className=" mt-8">
                拥抱技术，拥抱未来！一大波技术即将来袭~~~
              </Typography>
            </Fragment>
            {/* <div className="flex relative justify-center mt-10 ">
              <form>
                <Input
                  size="lg"
                  color="purple"
                  label="站内搜索"
                  icon={<i className="fab fa-github" />}
                  className="max-w-3xl w-[700px] "
                />
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
