// import "./App.css";

import Root from "./routes/Root";

function App() {
  // useEffect(() => {
  //   // 动态插入百度统计代码
  //   const hm = document.createElement("script");
  //   hm.src = "https://hm.baidu.com/hm.js?d13a626d6e21abf764fa4719d33278fc";
  //   hm.async = true;
  //   document.body.appendChild(hm);
  // }, []);

  return <Root />;
}

export default App;
