import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  // ScrollRestoration,
} from "react-router-dom";

// styles
// import "bootstrap/scss/bootstrap.scss";
// import "assets/scss/paper-kit.scss?v=1.3.0";
// import "assets/demo/demo.css?v=1.3.0";

// pages
import Home from "../pages/home";
import CommonTheme from "../pages/Theme/CommonTheme";

import TechnologyTheme from "@/pages/Theme/TechnologyTheme";
import ModuleLibrary from "@/pages/Feature/ModuleLibrary/Index";
import ComponentInfo from "@/pages/Feature/ModuleLibrary/ComponentInfo";
// forms
import TechRecordingForm from "@/pages/Form/Technology/TechRecordingForm";

import BigSpinner from "@/pages/BigSpinner";

export default function Root() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      title: "技术池",
    },
    {
      path: "/technology/",
      // element: <TechnologyTheme />,
      children: [
        {
          path: ":id",
          element: <TechnologyTheme />,
        },
        {
          path: "form/recording",
          element: <TechRecordingForm />,
        },
      ],
    },
    // {
    //   path: "/technology/form/recording",
    //   element: <TechRecordingForm />,
    // },
    {
      path: "/technology/features/module-library/",
      // element: <ModuleLibrary />,
      // 嵌套路由
      children: [
        {
          path: ":technologyId",
          element: <ModuleLibrary />, // 组件库
        },
        {
          path: ":id/:componentId",
          element: <ComponentInfo />, // 组件信息
        },
      ],
    },

    {
      path: "/landing-page",
      element: <CommonTheme />,
    },
    // {
    //   path: "/files",
    //   element: null,
    // },
  ]);

  // useEffect(() => {
  //   // 路由切换时，页面定位
  //   window.scrollTo(0, 0);
  //   console.log("路由切换，定位处理");
  // }, [props.history]);

  // // 解决跳转新页面滚动条不在顶部的问题
  // useEffect(() => {
  //   console.log("路由切换，定位处理");
  //   // if (document && props.history?.location?.pathname != "/") {
  //   //   // 可以排除不需要置顶的页面
  //   //   if (document?.documentElement || document?.body) {
  //   //     document.documentElement.scrollTop = document.body.scrollTop = 0; // 切换路由时手动置顶
  //   //   }
  //   // }
  //   // props.history.listen((location) => {
  //   //   console.log("监听");
  //   //   // 判断当前路由地址 和 发生变化后的 路由地址 是否一致
  //   //   if (this.props.location.pathname !== location.pathname) {
  //   //   }
  //   // });
  // }, [props.history?.location?.pathname]);

  // useEffect(() => {
  //   console.log("路由切换，定位处理");
  //   /* 监听路由的变化 */
  //   props.history.listen(() => {
  //     /*页面回到顶部 */
  //     if (document.body.scrollTop || document.documentElement.scrollTop > 0) {
  //       window.scrollTo(0, 0);
  //     }
  //   });
  // }, [props.history]);
  // useEffect(() => {
  //   // 路由切换时，页面定位
  //   window.scrollTo(0, 0);
  //   console.log(props.location);
  // }, [props.location]);
  // <ScrollRestoration />
  return (
    <>
      <RouterProvider
        router={router}
        fallbackElement={<BigSpinner />}
      ></RouterProvider>
    </>
  );
}
