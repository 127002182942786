import axios from "axios";

import { FileApiUrl } from "@/utils/GlobalVal";

// 收录技术
export async function uploadImg(formData: any) {
  axios.defaults.baseURL = FileApiUrl;
  let result: any = {};
  try {
    const { data } = await axios.post("/FileUpload/Upload", formData);
    result = data;
    console.log("api-Upload", result);
  } catch (error) {
    console.error("api-Upload-error", error);
  }
  return result;
}
