// 常规卡片
// 正方形 标题 图标 描述
import React from "react";

import { Link } from "react-router-dom";

export default function ProductCard(props: any) {
  const items = props.items as API.CardItemDataType[];

  return (
    <>
      {items.length > 0 &&
        items.map((item: API.CardItemDataType) => {
          return (
            <div className="relative w-full h-full">
              <Link
                to={
                  "/technology/features/module-library/67ff3b04-6326-11ed-a1be-fa6dfb0933c5/" +
                  item.id
                }
              >
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg shadow-sm"></div>
                </div>
                <div className="relative flex w-full h-full flex-col">
                  <div className="flex">
                    <div className="flex flex-col w-full text-center items-center pt-10 px-6 pb-8">
                      <div className=" relative max-w-[144px] max-h-[53px] mb-6 w-full h-full">
                        logo
                      </div>
                      <h1 className="">标题</h1>
                      <p>描述</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
}
