import React from "react";

import { Link } from "react-router-dom";

// 产品卡片
export default function Framework(props: any) {
  const items = props.data as API.ProductInfoType[];
  // console.log("组件接收数组", items);

  // 样式处理函数
  const funBgCss = (css: any) => {
    let cssResult = css;
    if (css.indexOf("#") > -1 || css.indexOf("rgb") > -1) {
      cssResult = "";
    }
    return cssResult;
  };
  const funCustomCss = (css: any) => {
    let cssResult = {};
    if (css.indexOf("#") > -1 || css.indexOf("rgb") > -1) {
      cssResult = { background: `${css}` };
    }
    return cssResult;
  };

  return (
    <>
      {items.length > 0 &&
        items.map((item: API.ProductInfoType) => {
          return (
            <div
              className={
                funBgCss(item.bgColor) +
                " shadow-lg rounded-lg text-center p-8 mt-8 cursor-pointer transition duration-700 transform hover:-translate-y-1 hover:scale-110 "
              }
              style={funCustomCss(item.bgColor)}
              key={item.id}
            >
              <Link
                to={{
                  pathname: "/technology/" + item.id,
                }}
              >
                <img
                  alt={item.title}
                  className="shadow-md rounded-full h-16 w-16 mx-auto p-2 bg-white"
                  src={item.logo}
                />
                <p className="text-lg text-white mt-4 font-semibold">
                  {item.title}
                </p>
                <p className="text-base text-white opacity-75 mt-2">
                  {item.subTitle}
                </p>
              </Link>
            </div>
          );
        })}
      {/* 
      <div className="bg-orange-500 shadow-lg rounded-lg text-center p-8">
        <img
          alt="..."
          className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/js.png"
        />
        <p className="text-lg text-white mt-4 font-semibold">JavaScript</p>
        <p className="text-base text-white opacity-75 mt-2">
          Object-oriented programming language that conforms to the ECMAScript
          specification.
        </p>
      </div>

      */}
    </>
  );
}
