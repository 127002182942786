import axios from "axios";

import { BaseUrl } from "@/utils/GlobalVal";

// 收录技术
export async function addTechnology(input: any) {
  axios.defaults.baseURL = BaseUrl;
  let result: any = {};
  try {
    const { data } = await axios.post("/technology", input);
    result["data"] = data;
    // console.log("api-addTechnology", data);
  } catch (error) {
    console.error("api-addTechnology", error);
  }
  return result;
}

// 获取分类下技术
export async function getTechnologies(input: any) {
  axios.defaults.baseURL = BaseUrl;
  let result: any = {};
  try {
    const { data } = await axios.get<API.TechDataType>(
      "/technology?categoryId=" + input
    );
    result["data"] = data;
    // console.log("api-getTechnologies", data);
  } catch (error) {
    console.error("api-getTechnologies", error);
  }
  return result;
}

// 获取指定技术
export async function getTechnology(input: any) {
  axios.defaults.baseURL = BaseUrl;
  let result: any = {};
  try {
    const { data } = await axios.get<API.TechInfoDataType>(
      "/technology/GetInfo?id=" + input
    );
    result["data"] = data;
    // console.log("api-getTechnology", data);
  } catch (error) {
    console.error("api-getTechnology", error);
  }
  return result;
}
