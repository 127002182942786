import React, { useEffect, useState } from "react";

import { Button, Typography } from "@material-tailwind/react";

import { formatNumber } from "@/assets/js/common";
import { Link } from "react-router-dom";

export default function ProductMainInfoSection(props: any) {
  let prdInfo = props.prdInfo;

  const [stars, setStars] = useState(0);

  useEffect(() => {
    if (prdInfo?.gitHubAPI) {
      fetch(prdInfo.gitHubAPI)
        .then((response) => response.json())
        .then((data) => setStars(formatNumber(data.stargazers_count, 1)));
    }
  }, [prdInfo?.gitHubAPI]);

  // 样式处理函数
  const funLogo = (logo: any) => {
    let result = logo;
    if (logo && logo !== undefined) {
      if (logo.indexOf("https://") > -1 || logo.indexOf("http://") > -1) {
      } else {
        result = "/" + logo;
      }
    }
    return result;
  };

  return (
    <div
      id="productPage"
      className="section-header gradient-blue"
      data-turbolinks="false"
    >
      <div className="container product-header-centered">
        <div className="flex flex-nowrap items-center text-white">
          <div className=" w-1/2">
            <div
              className="product-image bg-center bg-cover flex justify-center items-center"
              style={{
                backgroundImage: `url(${require("../../assets/img/bg-prd.jpg")})`,
              }}
            >
              <div>
                {/* <a
                  href="https://demos.creative-tim.com/paper-kit-react/#/index"
                  target="_blank"
                  rel="noreferrer"
                >
                </a> */}
                <img
                  // src={require(`${productInfo.logo}`)}
                  src={funLogo(prdInfo.logo)}
                  alt={prdInfo.title}
                  loading="lazy"
                  className="w-20 h-20 rounded-xl "
                />
              </div>
            </div>
            <div className="flex justify-center">
              <Link
                to={"/technology/features/module-library/" + prdInfo.id}
                className="block px-6 py-2.5  tracking-wider text-center text-lg font-semibold text-white  transition-colors duration-300 transform bg-[#8c4bff] rounded-md"
              >
                组件库
              </Link>
            </div>
          </div>
          <div className="w-1/2 ml-5">
            <div className="">
              <div className="text-center">
                <Typography variant="h1" className="font-bold text-9xl py-6">
                  {prdInfo.title === undefined ? "" : prdInfo.title}
                </Typography>
                <Typography variant="lead">
                  {prdInfo.subTitle === undefined ? "" : prdInfo.subTitle}
                </Typography>
                <div className="mt-10 flex justify-center gap-8">
                  <a
                    href={prdInfo.url}
                    target="_blank"
                    className="block px-6 py-2.5 text-sm font-medium tracking-wider text-center text-white transition-colors duration-300 transform bg-gray-900 rounded-md hover:bg-blue-700"
                    rel="noreferrer"
                  >
                    官网
                  </a>
                  {prdInfo.gitHubUrl ? (
                    <a
                      href={prdInfo.gitHubUrl}
                      target="_blank"
                      className="flex items-center gap-1 px-2 py-2.5 text-sm font-medium tracking-wider text-center text-white transition-colors duration-300 transform bg-gray-900 rounded-md hover:bg-blue-700"
                      rel="noreferrer"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="h-6 w-6 fill-white"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 2C6.477 2 2 6.463 2 11.97c0 4.404 2.865 8.14 6.839 9.458.5.092.682-.216.682-.48 0-.236-.008-.864-.013-1.695-2.782.602-3.369-1.337-3.369-1.337-.454-1.151-1.11-1.458-1.11-1.458-.908-.618.069-.606.069-.606 1.003.07 1.531 1.027 1.531 1.027.892 1.524 2.341 1.084 2.91.828.092-.643.35-1.083.636-1.332-2.22-.251-4.555-1.107-4.555-4.927 0-1.088.39-1.979 1.029-2.675-.103-.252-.446-1.266.098-2.638 0 0 .84-.268 2.75 1.022A9.607 9.607 0 0 1 12 6.82c.85.004 1.705.114 2.504.336 1.909-1.29 2.747-1.022 2.747-1.022.546 1.372.202 2.386.1 2.638.64.696 1.028 1.587 1.028 2.675 0 3.83-2.339 4.673-4.566 4.92.359.307.678.915.678 1.846 0 1.332-.012 2.407-.012 2.734 0 .267.18.577.688.48 3.97-1.32 6.833-5.054 6.833-9.458C22 6.463 17.522 2 12 2Z"
                        ></path>
                      </svg>
                      {stars}
                    </a>
                  ) : (
                    ""
                  )}
                  {prdInfo.docUrl ? (
                    <a
                      href={prdInfo.url}
                      target="_blank"
                      className="block px-6 py-2.5 text-sm font-medium tracking-wider text-center text-white transition-colors duration-300 transfor bg-cyan-600 rounded-md hover:bg-blue-700"
                      rel="noreferrer"
                    >
                      官方教程
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
