// 通用布局
import React from "react";

// page components
import Navbar from "@/components/layout/navbar";
import Footer from "@/components/layout/footer";

export default function CommonLayout(props: any) {
  return (
    <>
      <Navbar shadow />
      {props.children}

      <Footer />
    </>
  );
}
