import React, { useEffect, useState } from "react";

import { ReadFileContent } from "@/utils/FileUtil";
import SimpleMarkdown from "../essay/simpleMarkdown";

export default function ProductDescSection(props: any) {
  // fetch("/static/articles/details/react.md")
  //   .then((response) => {
  //     return response.text();
  //   })
  //   .then((data) => {
  //     console.log("读取指定MarkDwon文件内容：", data);
  //   });
  const prdInfo = props.prdInfo as API.TechInfoDataType;
  const [desc, setDesc] = useState("");

  if (prdInfo.descFile) {
    ReadFileContent(prdInfo.descFile).then((data) => {
      // console.log("技术详细介绍", data);
      setDesc(data);
    });
  }
  useEffect(() => {
    setDesc(prdInfo.desc == undefined ? "" : prdInfo.desc);
  }, [prdInfo.desc]);

  return (
    <div className="section-product-description section-white section-scaled">
      <div className="container ">
        <div className="flex flex-row">
          <div className="basis-2/3">
            <div className="">
              {/* markdown */}
              <SimpleMarkdown content={desc} />
            </div>
          </div>
          {/* <div className="basis-1/3">评论</div> */}
        </div>
      </div>
    </div>
  );
}
