// 组件库
import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-tailwind/react";

// 布局
import CommonLayout from "@/layouts/CommonLayout";

// 组件

export default function Index(props: any) {
  return (
    <>
      <CommonLayout>
        <main className="relative bg-[#f2f4ff] min-h-screen">
          <section className="py-20 lg:py-32 text-center ">
            <div>
              <Typography
                variant="h1"
                className="pb-10 font-semibold tracking-normal text-[#1A237E]"
              >
                组件信息
              </Typography>
              <Typography variant="h5" className=" text-[#1A237E]/60">
                这是某个组件
              </Typography>
              <Link
                to={
                  "/technology/features/module-library/67ff3b04-6326-11ed-a1be-fa6dfb0933c5"
                }
              >
                返回组件库
              </Link>
            </div>
          </section>
          {/* <section>
            <div className="relative max-w-screen-xl mx-auto">
              <div className="relative max-w-screen-lg mx-auto ">
                <div className="py-5">筛选条件</div>
                <div className="py-10 grid grid-cols-3 gap-10">
                  <ProductCard items={CardItems} />
                </div>
                <div className="relative text-center py-10">
                  <button className="px-8 py-4 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 ease-in-out delay-150 duration-300">
                    查看更多
                  </button>
                </div>
              </div>
            </div>
          </section> */}
        </main>
      </CommonLayout>
    </>
  );
}
