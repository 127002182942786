import React from "react";
import ReactMarkdown from "react-markdown";

import "github-markdown-css/github-markdown.css";

export default function SimpleMarkdown(props: any) {
  return (
    <article className="markdown-body">
      <ReactMarkdown children={props.content} />
    </article>
  );
}
