import React, { useEffect, useState } from "react";

import "@/assets/css/product.css";

import Navbar from "@/components/layout/navbar";
import ProductMainInfoSection from "@/components/sections/productMainInfoSection";
import ProductDescSection from "@/components/sections/productDescSection";
import Footer from "@/components/layout/footer";
import { useParams } from "react-router-dom";
import useProductData from "@/models/ProductModel";
import { getTechnology } from "@/services/TechnologyService";

// 通用技术主题首页
export default function TechnologyTheme() {
  let { id } = useParams();
  const [prdInfo, setPrdInfo] = useState<API.TechInfoDataType>({});
  // 使用自定义hook
  const { productInfos } = useProductData({});
  // 读取当前产品信息
  const currentPrdId = id;
  useEffect(() => {
    const productInfo = productInfos.filter(
      (prd) => prd.id === currentPrdId
    )[0];
    console.log("productInfos=", productInfos, "currentPrd=", productInfo);

    if (productInfo === undefined || productInfo === null) {
      getTechnology(currentPrdId).then((res) => {
        console.log("获取技术信息：", res.data);
        setPrdInfo(res.data);
      });
    } else {
      setPrdInfo(productInfo);
    }
  }, [currentPrdId]);

  // 相当于 componentDidMount 和 componentDidUpdate
  useEffect(() => {
    // 使用浏览器的 API 更新页面标题
    document.title = `${prdInfo.title} | 技术池`;
  });

  return (
    <>
      <Navbar />
      <div className="product-page">
        <main>
          <ProductMainInfoSection prdInfo={prdInfo} />
          <ProductDescSection prdInfo={prdInfo} />
        </main>
        <Footer />
      </div>
    </>
  );
}
