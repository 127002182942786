// const axios = require("axios").default;
import axios from "axios";

import { BaseUrl } from "@/utils/GlobalVal";

// export type DataTreeType = {
//   id?: string;
//   title?: string;
//   parentId?: string;
// };

// 获取所有分类
export async function getCategory() {
  axios.defaults.baseURL = BaseUrl;
  let result: any = {};
  try {
    const { data } = await axios.get<{ data: API.Category.CategoryDataType }>(
      "/category"
    );
    result["data"] = data;
    console.log("api-data", data);
  } catch (error) {
    console.error(error);
  }
  return result;
}

// 获取树形分类结构
export async function GetTreeCategory() {
  axios.defaults.baseURL = BaseUrl;
  let result: any = {};
  try {
    const { data } = await axios.get<{
      data: API.Category.TreeCategoryDataType;
    }>("/category/tree");
    result["data"] = data;
  } catch (error) {
    console.error("GetTreeCategory-ERROR", error);
  }
  return result;
}
