//获取环境变量
const REACT_APP_ENV_VAR = process.env.NODE_ENV;

const BaseUrl =
  REACT_APP_ENV_VAR === "development"
    ? "https://localhost:44331/api/jishuchi"
    : "https://api.jishuchi.cn/api/jishuchi";

const ReactApiUrl =
  REACT_APP_ENV_VAR === "development"
    ? "http://localhost:3000/api/reactApi"
    : "";

// 模块 OSS
const FileApiUrl =
  REACT_APP_ENV_VAR === "development"
    ? "https://localhost:44330/api/OSSModule"
    : "https://oss.jishuchi.cn/api/OSSModule";

export { BaseUrl, FileApiUrl, ReactApiUrl };
