import React, { useEffect, useState } from "react";

// @material-tailwind/react components
import { Card, Typography } from "@material-tailwind/react";

import "@/assets/css/home.css";

// page components
import Navbar from "@/components/layout/navbar";
import Header from "@/components/layout/header";
import Footer from "@/components/layout/footer";
import Framework from "@/components/cards/framework";
// import RoundCard from "@/components/cards/RoundCard";
import LongCard from "@/components/cards/LongCard";

import useProductData from "@/models/ProductModel";
import { getTechnologies } from "@/services/TechnologyService";

export default function Home() {
  // 使用自定义hook
  const { productInfos } = useProductData({});

  // 技术产品数组
  const [languages, setLanguages] = useState<API.TechInfoDataType[]>([]);
  const [prds, setPrds] = useState<API.TechInfoDataType[]>([]);
  const [backendPrds, setBackendPrds] = useState<API.TechInfoDataType[]>([]);
  const [dbPrds, setDBPrds] = useState<API.TechInfoDataType[]>([]);
  const [middlewarePrds, setMiddlewarePrds] = useState<API.TechInfoDataType[]>(
    []
  );
  const [devOpsPrds, setDevOpsPrdsPrds] = useState<API.TechInfoDataType[]>([]);

  // 前端产品
  const frontEndPrds = productInfos.filter(
    (prd) => prd.categoryId === "23227021-67dc-11ed-b67e-caa76bf5716c"
  );

  useEffect(() => {
    // 编程语言 获取分类下技术
    getTechnologies("62f5704e-9676-11ed-ab01-9572317fd412").then((res) => {
      if (res.data) {
        const tempArr: API.TechInfoDataType[] = [];
        // 数据转换
        const lstTech: API.TechDataType[] = res.data;
        lstTech.forEach((item) => {
          const temp: API.TechInfoDataType = {
            id: item.id,
            title: item.title,
            subTitle: item.subTitle,
            logo: item.logo,
            bgColor: item.bgColor,
          };

          tempArr.push(temp);
          setLanguages(tempArr);
        });
      }
    });

    // 前端框架 获取分类下技术
    getTechnologies("23227021-67dc-11ed-b67e-caa76bf5716c").then((res) => {
      if (res.data) {
        // 数据转换
        const lstTech: API.TechDataType[] = res.data;
        lstTech.forEach((item) => {
          const temp: API.TechInfoDataType = {
            id: item.id,
            title: item.title,
            subTitle: item.subTitle,
            logo: item.logo,
            bgColor: item.bgColor,
          };

          frontEndPrds.push(temp);
          setPrds(frontEndPrds);
        });
      }
    });

    // 后端框架
    getTechnologies("db1ccff9-94ab-11ed-900c-9572317fd412").then((res) => {
      if (res.data) {
        const tempArr: API.TechInfoDataType[] = [];
        // 数据转换
        const lstTech: API.TechDataType[] = res.data;
        lstTech.forEach((item) => {
          const temp: API.TechInfoDataType = {
            id: item.id,
            title: item.title,
            subTitle: item.subTitle,
            logo: item.logo,
            bgColor: item.bgColor,
          };
          tempArr.push(temp);
          setBackendPrds(tempArr);
        });
      }
    });

    // 数据库
    getTechnologies("6a7d0d47-94cc-11ed-968d-9572317fd412").then((res) => {
      if (res.data) {
        const tempArr: API.TechInfoDataType[] = [];
        // 数据转换
        const lstTech: API.TechDataType[] = res.data;
        lstTech.forEach((item) => {
          const temp: API.TechInfoDataType = {
            id: item.id,
            title: item.title,
            subTitle: item.subTitle,
            logo: item.logo,
            bgColor: item.bgColor,
          };
          tempArr.push(temp);
          setDBPrds(tempArr);
        });
      }
    });

    // 中间件
    getTechnologies("8fdd830b-95a3-11ed-813d-9572317fd412").then((res) => {
      if (res.data) {
        const tempArr: API.TechInfoDataType[] = [];
        // 数据转换
        const lstTech: API.TechDataType[] = res.data;
        lstTech.forEach((item) => {
          const temp: API.TechInfoDataType = {
            id: item.id,
            title: item.title,
            subTitle: item.subTitle,
            logo: item.logo,
            bgColor: item.bgColor,
          };
          tempArr.push(temp);
          setMiddlewarePrds(tempArr);
        });
      }
    });

    // DevOps
    getTechnologies("82775d18-95ab-11ed-9c43-9572317fd412").then((res) => {
      if (res.data) {
        const tempArr: API.TechInfoDataType[] = [];
        // 数据转换
        const lstTech: API.TechDataType[] = res.data;
        lstTech.forEach((item) => {
          const temp: API.TechInfoDataType = {
            id: item.id,
            title: item.title,
            subTitle: item.subTitle,
            logo: item.logo,
            bgColor: item.bgColor,
          };
          tempArr.push(temp);
          setDevOpsPrdsPrds(tempArr);
        });
      }
    });
  }, []);

  return (
    <>
      <Navbar shadow />

      <main className="relative bg-white">
        <Header />
        <div className="relative flex flex-col bg-clip-border rounded-xl text-gray-700 mx-6 -mt-20 bg-white md:mx-12 md:-mt-48">
          <div className="container z-20 mx-auto px-4">
            <section className="py-8 lg:py-12">
              <div className="mx-auto mb-4 w-full text-center md:w-3/4">
                <Typography
                  variant="h2"
                  className="mb-2 font-semibold tracking-normal text-[#1A237E]"
                >
                  编程语言（Programming Language）
                </Typography>
                <Typography className="mb-2 lead text-[#1A237E]/60">
                  热门编程语言，包括但不限于前端、后端等开发语言。
                </Typography>
              </div>
              <div className="container px-6 mx-auto">
                <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 ">
                  <LongCard data={languages} />
                </div>
              </div>
            </section>

            <section className="py-8 lg:py-12">
              <div className="mx-auto mb-4 w-full text-center md:w-3/4">
                <Typography
                  variant="h2"
                  className="mb-2 font-semibold tracking-normal text-[#1A237E]"
                >
                  前端框架（Front End Framework）
                </Typography>
                <Typography className="mb-2 lead text-[#1A237E]/60">
                  前端框架是用来简化网页设计的框架，它帮助开发人员可以更快速的开发出一个网页。Web前端发展至今，演变出了无数优质的框架，本站汇集所有优质框架供君学习。
                </Typography>
              </div>
              <div className="container px-6 mx-auto">
                <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                  <Framework data={prds} />
                </div>
              </div>
            </section>

            <section className="py-8 lg:py-12">
              <div className="mx-auto mb-4 w-full text-center md:w-3/4">
                <Typography
                  variant="h2"
                  className="mb-2 font-semibold tracking-normal text-[#1A237E]"
                >
                  后端框架（Back End Framework）
                </Typography>
                <Typography className="mb-2 lead text-[#1A237E]/60">
                  使用前沿久经沙场的后端技术框架，为应用程序建设提供更快速和标准化的开发方式.
                </Typography>
              </div>
              <div className="container px-6 mx-auto">
                <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                  <Framework data={backendPrds} />
                </div>
              </div>
            </section>

            <section className="py-8 lg:py-12">
              <div className="mx-auto mb-4 w-full text-center md:w-3/4">
                <Typography
                  variant="h2"
                  className="mb-2 font-semibold tracking-normal text-[#1A237E]"
                >
                  数据库（DataBase）
                </Typography>
                <Typography className="mb-2 lead text-[#1A237E]/60">
                  热门数据库
                </Typography>
              </div>
              <div className="container px-6 mx-auto">
                <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                  <Framework data={dbPrds} />
                </div>
              </div>
            </section>

            <section className="py-8 lg:py-12">
              <div className="mx-auto mb-4 w-full text-center md:w-3/4">
                <Typography
                  variant="h2"
                  className="mb-2 font-semibold tracking-normal text-[#1A237E]"
                >
                  中间件（Middleware）
                </Typography>
                <Typography className="mb-2 lead text-[#1A237E]/60">
                  热门中间件
                </Typography>
              </div>
              <div className="container px-6 mx-auto">
                <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                  <Framework data={middlewarePrds} />
                </div>
              </div>
            </section>

            <section className="py-8 lg:py-12">
              <div className="mx-auto mb-4 w-full text-center md:w-3/4">
                <Typography
                  variant="h2"
                  className="mb-2 font-semibold tracking-normal text-[#1A237E]"
                >
                  DevOps
                </Typography>
                <Typography className="mb-2 lead text-[#1A237E]/60">
                  DevOps 是开发 (Dev) 和运营 (Ops)
                  的复合词，它将人、流程和技术结合起来，不断地为客户提供价值。
                  {/* DevOps 对团队意味着什么？ DevOps
                  使以前孤立的角色（开发、IT运营、质量工程和安全）可以协调和协作，以生产更好、更可靠的产品。通过采用
                  DevOps
                  文化、做法和工具，团队能够更好地响应客户需求，增强对所构建应用程序的信心，更快地实现业务目标。 */}
                </Typography>
              </div>
              <div className="container px-6 mx-auto">
                <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                  <Framework data={devOpsPrds} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
