import React from "react";

import { Link } from "react-router-dom";

// 长形卡片
export default function LongCard(props: any) {
  const items = props.data as API.ProductInfoType[];

  return (
    <>
      {items.length > 0 &&
        items.map((item: API.ProductInfoType) => {
          return (
            <Link
              to={{
                pathname: "/technology/" + item.id,
              }}
              className="flex relative items-center p-5 mb-5 bg-[#f0f4f9] rounded-lg text-[#64f] justify-start w-[calc(50% - 10px)] shadow-md"
            >
              <span className="flex items-center justify-center w-[40px] h-[40px] mr-5">
                <img alt={item.title} src={item.logo} />
              </span>
              <b className="w-[calc(100% - 60px)]"> {item.title}</b>
              <i className="opacity-0 transition transform hover:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </i>
            </Link>
          );
        })}
    </>
  );
}
