import axios from "axios";

// 读取指定文件内容
export async function ReadFileContent(filePath: string) {
  let result = "";
  result = await fetch(filePath).then((response) => {
    return response.text();
  });
  return result;
}
